/*
 * Created by Benneth on 23 June 2022.
 */

import BanquetingAvailabilityTypes from '../constants/action-types/banquetingAvailabilityTypes'
import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({
  banquet: [],
},{async: true,});

export default (state = DEFAULT_STATE, action) => {

  switch (action.type){
    case BanquetingAvailabilityTypes.Request:
      return StateUtility.defaultRequest(state);

    case BanquetingAvailabilityTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case BanquetingAvailabilityTypes.Set:
      return StateUtility.defaultCompleteRequest({
        ...state,
        banquet: action.data,
      });

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }
}