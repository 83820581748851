/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {createTheme} from "@hti-ui/react-web-material";
import Palette from "./palette";
import Color from "color";

const DarkPalette = {
  ...Palette,
  secondary: Color(Palette.secondary).lighten(.6).toString(),
};

/**
 * The dark mode theme for the app.
 */
const Theme = createTheme({
  baseColor: DarkPalette.charcoal,
  complimentColor: DarkPalette.white,
  secondaryColor: DarkPalette.secondary,
  customColors: DarkPalette,
});

export default Theme;
