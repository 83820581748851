/*
 * Created by Paul Engelke on 17 November 2021.
 *
 * A set of actions for managing the app appearance.
 */

import AppAppearanceActionTypes
  from "../constants/action-types/appAppearanceActionTypes";
import ThemeTypes from "../constants/themeTypes";
import LocalStorage from "../core/local-storage";

/**
 * Initializes the app's appearance with the given overrides.
 * @param {Object} args.options The app appearance options.
 * @param {Object} args.options.theme The theme type.
 * @return {function(*,*): void}
 * @see ThemeTypes
 */
export const initAppearance = args => dispatch => {
  dispatch({type: AppAppearanceActionTypes.Init, options: args?.options});
};

/**
 * Sets the theme for the app's appearance.
 * @param {string} args.theme The theme type.
 * @param {boolean} args.persist Should the theme be persisted to local storage?
 * @return {function(*,*): void}
 * @see ThemeTypes
 */
export const setTheme = args => (dispatch, getState) => {

  const {theme = ThemeTypes.Light, persist = false} = args;

  if (persist) {
    const appAppearance = getState().appAppearance;
    LocalStorage.AppAppearance.set({...appAppearance, theme})
  }

  dispatch({type: AppAppearanceActionTypes.SetTheme, theme});

};
