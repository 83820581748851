/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the customer redux state.
 */
const CustomerActionTypes = ActionTypeUtility.createActionTypes(
    "Customer",
    {
      Request: "Request",
      FailRequest: "FailRequest",
      CompleteRequest: "CompleteRequest",
      Set: "Set",
      Merge: "Merge",
    },
);

export default CustomerActionTypes;
