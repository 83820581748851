/*
 * Created by Paul Engelke on 17 November 2021.
 */

import PropTypes from "prop-types";
import {NavigationBarCrest} from "@hti-ui/react-web-material";
import {makeStyles, Typography} from "@material-ui/core";
import logo from "../../../assests/images/png/apex-night-reports-DeviceOnly-logo22.png"

/**
 * A component that serves as the application's logo and a button for opening
 * the navigation drawer.
 */
const Crest = props => {
  const classes = useStyles();
  return (<NavigationBarCrest onClick={props.onClick}>
    <img
        className={classes.logo}
        src={logo}
        alt={'apex-night-reports-DeviceOnly-logo22'}
    />
  </NavigationBarCrest>);
};

const useStyles = makeStyles({
  logo: {width: 40, height: 42},
});

Crest.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Crest;

