/*
 * Created by Paul Engelke on 25 March 2021.
 */

import {NavigationDrawerMenuItem} from "@hti-ui/react-web-material";
import PropTypes from 'prop-types';

/**
 * A custom menu item that can be used to render a node in the navigation
 * drawer, with an icon.
 */
const DrawerMenuItem = props => {
  const {node} = props;
  return (<NavigationDrawerMenuItem
      label={node.label ?? "???"}
      onClick={node.onClick}
      Icon={node.quickLink?.IconComponent}
      leaf={!node.children?.length}
  />);
};

DrawerMenuItem.propTypes = {
  node: PropTypes.object,
};

export default DrawerMenuItem;
