/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {useCallback} from "react";
import useSession from "./useSession";
import useWorkspace from "./useWorkspace";

/**
 * Provides a callback hook that checks if the current user has the necessary
 * authorization to perform some action or access some part of the app.
 * @return {function({rights: Object[], all?: boolean}): boolean}
 * @see useAuthorizationFlag
 */
const useAuthorizationCallback = () => {

  const session = useSession();
  const user = session.user;
  const userRole = session.userRole;

  const workspace = useWorkspace();
  const customerLicence = workspace.customerLicence;
  const propertyLicence = workspace.propertyLicence;

  /**
   * Checks if the current user has the necessary authorization to perform
   * some action or access some part of the app.
   *
   * @param {Object[]} args.rights A list of required user rights.
   * @param {all=true} args.all Should every right be required or just at
   * least one?
   * @return {boolean}
   */
  return useCallback(args => {
    // todo determine if the user is authorized to perform an action or
    //  access a part of the app.
    return true;
  }, [user, userRole, customerLicence, propertyLicence]);

};

export default useAuthorizationCallback;
