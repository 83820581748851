/*
 * Created by Paul Engelke on 18 November 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * Provides a set of action types for managing the global messenger redux state.
 */
const GlobalMessengerActionTypes = ActionTypeUtility.createActionTypes(
    "GlobalMessenger",
    {
      Enqueue: "Enqueue",
      Dequeue: "Dequeue",
    },
);

export default GlobalMessengerActionTypes;
