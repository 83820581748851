/*
 * Created by Paul Engelke on 25 March 2021.
 */


import JwtTokenModel from "./jwtTokenModel";
import AppAppearanceModel from "./appAppearanceModel";

/**
 * An interface for interacting with local storage.
 */
export default class LocalStorage {

  /**
   * The user's JWT token.
   */
  static get JwtToken() {
    return new JwtTokenModel();
  }

  /**
   * The user's app appearance preferences.
   */
  static get AppAppearance() {
    return new AppAppearanceModel();
  }

}
