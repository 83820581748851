/*
 * Created by Paul Engelke on 18 November 2021.
 */

import {useSelector} from "react-redux";

/**
 * Provides access to the current session redux state.
 * @return {Object}
 */
const useSession = () => useSelector(state => state.session);
export default useSession;
