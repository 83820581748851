/*
 * Created by Paul Engelke on 18 November 2021.
 */

import {useSelector} from "react-redux";
import {useActions} from "@hti-ui/redux-core";
import {dequeueGlobalMessage} from "../actions/globalMessengerActions";
import {useCallback} from "react";
import {
  Snackbar,
  SnackbarActions,
  SnackbarContent
} from "@hti-ui/react-web-material";
import {Button} from "@material-ui/core";

/**
 * A snackbar to be appended to the 'root' app component.
 *
 * This component is responsible for displaying global messages and de-queuing
 * them from the global message redux state.
 */
const GlobalSnackbar = () => {

  const messages = useSelector(state => state.globalMessenger.queue);
  const current = messages?.[0];

  const _dequeue = useActions(dequeueGlobalMessage);
  const dismissMessage = useCallback(() => _dequeue(), [current]);

  const visible = !!current;
  const duration = current?.options?.duration ?? 3000;
  const type = current?.type;
  const dismissible = current?.options?.dismissible;
  const hasCustomActions = current?.options?.actions;
  const hasActions = dismissible || hasCustomActions;

  return (<Snackbar
      type={type}
      visible={visible}
      duration={duration}
      onShowComplete={dismissMessage}
  >
    <SnackbarContent>{current?.description ?? ""}</SnackbarContent>
    {hasActions && <SnackbarActions>
      {dismissible
      && <Button variant={"outlined"} onClick={dismissMessage}>Okay</Button>}
    </SnackbarActions>}
  </Snackbar>);

};

export default GlobalSnackbar;
