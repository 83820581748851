/*
 * Created by Benneth Nchabeleng on 20 May 2022.
 */

import ViewReportActionTypes from "../../constants/action-types/viewReportActionType";
import Http from "../../core/http/http";

export const displayReport = (args) => async (dispatch) => {

    try {

      dispatch({type: ViewReportActionTypes.Request});

      const response = await Http.request({
        method: Http.Method.GET,
        url: `display/${args.user.propCode}/${args.user.siteCode}/${args.selectedReport}/${args.selectedDate}/${args.empSiteCode}`,
      });

      const display = response.data
      dispatch({type: ViewReportActionTypes.Set, data: display});

      const tab = window.open();
      tab.window.document.write('<p> Loading.....</p>');

      const file = new Blob(
          [response.data],
          {type: 'application/pdf'});

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // const fileURL = URL.createObjectURL();
      //Open the URL on new Window
      tab.location.href = (fileURL);

      return response.data;
    } catch (e) {
      dispatch({type: ViewReportActionTypes.FailRequest});
      throw e;
    }

  }