/* 
 * Created by Paul Engelke on 21 May 2019.
 */

import {BasePalette} from "@hti-ui/react-web-material";

/**
 * The app's color palette.
 */
const Palette = {
  ...BasePalette,
  secondary: BasePalette.green,
};

export default Palette;
