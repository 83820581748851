import ReportsActionTypes from '../constants/action-types/reportsActionType'
import {StateUtility} from "@hti-ui/redux-core";

const DEFAULT_STATE = StateUtility.State({
  report: [],
},{async: true,});

export default (state = DEFAULT_STATE, action) => {
  switch (action.type){
    case ReportsActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case ReportsActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case ReportsActionTypes.Set:
      return StateUtility.defaultCompleteRequest({
        ...state,
        report: action.data,
      });

    default:
      return state;
  }
}