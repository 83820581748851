/*
 * Created by Paul Engelke on 19 November 2021.
 */

/**
 * A set of possible error codes that can be encountered.
 */
const ErrorCodes = Object.freeze({

  /**
   * The request timed out and was aborted by Axios.
   *
   * This code is as it is given by Axios, do not change its value!
   */
  ConnectionTimeout: "ECONNABORTED",

  Timeout: "Timeout",

  /**
   * Covers any error that has not been accounted.
   */
  Unknown: "Unknown",

  Validation: "exceptionType_validation",
  NotFound: "exceptionType_notFound",
  DeletionReferenceIntegrityViolation: "exceptionType_deletionReferenceIntegrityViolation",

});

export default ErrorCodes;
