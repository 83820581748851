/* 
 * Created by Paul Engelke on 10 May 2019.
 */

import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";

const styles = () => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});

/**
 * The root component for all application pages.
 *
 * @return {*}
 * @constructor
 */
const Page = ({classes, children}) => (<div className={classes.page}>
  {children}
</div>);

Page.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Page);
