/*
 * Created by Paul Engelke on 17 November 2021.
 */

import React from "react";
import PropTypes from "prop-types";
import useSessionStatus from "./useSessionStatus";
import SessionStatuses from "./sessionStatuses";
import useAuthorizationFlag from "../../hooks/useAuthorizationFlag";
import {Redirect, Route} from "react-router-dom";
import RouteNames from "../../constants/routeNames";
import SplashScreen from "../SplashScreen";

/**
 * A route that is protected by user authentication. If the route is accessed
 * and no valid authentication is provided, the user will be redirected to the
 * sign-in page.
 *
 * @see https://reacttraining.com/react-router/web/api/Route
 */
const PrivateRoute = props => {

  const {
    component: Component,
    userRights, requireAllUserRights,
    ...otherProps
  } = props;

  const sessionStatus = useSessionStatus();
  const authenticated = sessionStatus === SessionStatuses.Authenticated;

  const requiredRights = userRights ?? [];
  const requiresAuthorization = requiredRights.length > 0;
  const requireAllRights = !!requireAllUserRights;
  const authorized = useAuthorizationFlag(requiredRights, !!requireAllRights);
  const allowAccess = authenticated && (!requiresAuthorization || authorized);

  return (<Route

      {...otherProps}

      render={props => {

        if (sessionStatus === SessionStatuses.Pending) {
          return (<SplashScreen/>);
        }

        return allowAccess
            ? (<Component {...props}/>)
            : (<Redirect
                to={{
                  pathname: authenticated
                      ? RouteNames.App
                      : RouteNames.SignInPage,
                  state: {from: props.location}
                }}
            />);

      }}

  />);

};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  userRights: PropTypes.array,
  requireAllUserRights: PropTypes.bool,
};

export default PrivateRoute;
