/*
 * Created by Paul Engelke on 30 November 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import PropertyActionTypes from "../constants/action-types/propertyActionTypes";

const DEFAULT_STATE = StateUtility.State({
  property: [],
},{async: true,});

/**
 * A redux reducer for property data.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PropertyActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case PropertyActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case PropertyActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);

    case PropertyActionTypes.Set:
      return StateUtility.defaultCompleteRequest({
        ...state,
        property: action.data
      });

    default:
      return state;

  }

};
