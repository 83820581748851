/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {useCallback, useMemo, useState} from "react";
import useNavigationTree from "../useNavigationTree";
import {
  NavigationBar as HtiNavigationBar,
  NavigationBarButtonGroup
} from "@hti-ui/react-web-material";
import Crest from "./Crest";
import QuickLinks from "./QuickLinks";
import NavigationDrawer from "../NavigationDrawer";
import UserWidget from "./UserWidget";
import AppSwitcher from "./AppSwitcher";
import {makeStyles} from "@material-ui/core";

/**
 * The primary navigation bar for the app.
 */
const NavigationBar = () => {

  const navTree = useNavigationTree();
  const defaultNavState = useMemo(() => ({term: "", stack: []}), []);
  const [navState, setNavState] = useState(defaultNavState);

  const [showDrawer, toggleDrawer] = useState(false);
  const openDrawer = useCallback(() => toggleDrawer(true), []);
  const closeDrawer = useCallback(() => {
    setNavState(defaultNavState);
    toggleDrawer(false);
  }, [defaultNavState]);

  /**
   * Handles navigation behaviour when a quick link is clicked.
   */
  const onQuickLinkClicked = useCallback(node => {

    if (node.onClick) {
      // The node refers to a screen in the app.
      // All we need to do is navigate to the screen.
      node.onClick();
      return;
    }

    // Otherwise, we need to set the navigation state to point to the node and
    // open the drawer.

    const hasChildren = node.children?.length > 0;
    const stack = hasChildren ? [node.id] : [];
    setNavState(prev => ({...prev, stack}));
    toggleDrawer(hasChildren);

  }, []);

  return (<HtiNavigationBar>
    <Crest onClick={openDrawer}/>
    <QuickLinks tree={navTree} onQuickLinkClicked={onQuickLinkClicked}/>
    <NavigationBarButtonGroup align={"bottom"}>
      {/*<AppSwitcher/>*/}
      <UserWidget/>
    </NavigationBarButtonGroup>
    <NavigationDrawer
        open={showDrawer}
        onClose={closeDrawer}
        navigationTree={navTree}
        navigationState={navState}
        onNavigationStateChanged={setNavState}
    />
  </HtiNavigationBar>);

};

export default NavigationBar;
