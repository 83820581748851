/*
 * Created by Paul Engelke on 19 November 2021.
 */

import ErrorCodes from "../../constants/errorCodes";

/**
 * An error entity for all errors that occur within the app or as a response
 * from the server.
 */
export default class AppError extends Error {

  /**
   * The HTTP status code, as applicable.
   * @type {number|null}
   * @private
   */
  _status = null;

  /**
   * The error code, that indicates the type of error that occurred. This can
   * be used to provide a descriptive, localized error to the user or to
   * perform some contextualized action in response to the error.
   * @type {string|null}
   * @private
   */
  _code = null;

  /**
   * A simple message describing the error that occurred. This is mostly
   * useful for developers but may also act as a fallback description to
   * display to the user if no localized message can be determined.
   * @type {string|null}
   * @private
   */
  _message = null;

  /**
   * An explanation for the error in free form. This may simply be an english
   * message or a structure that describes what went wrong. This is mostly
   * useful to developers.
   * @type {*}
   * @private
   */
  _explanation = null;

  /**
   * The original error entity, as applicable.
   * @type {Object}
   * @private
   */
  _cause = null;

  /**
   * Creates a new app error instance.
   * @param [args.cause] THe original error entity.
   * @param [args.status] The HTTP status code, as applicable.
   * @param [args.code] The error code, that indicates the type of error that
   * occurred. This can be used to provide a descriptive, localized error to
   * the user or to perform some contextualized action in response to the error.
   * @param [args.message] A simple message describing the error that occurred.
   * This is mostly useful for developers but may also act as a fallback
   * description to display to the user if no localized message can be
   * determined.
   * @param [args.explanation] An explanation for the error in free form.
   * This may simply be an english message or a structure that describes
   * what went wrong. This is mostly useful to developers.
   */
  constructor(args) {

    super();

    const cause = args?.cause;
    if (cause) {

      this._cause = cause;

      if (cause.response) {
        // An HTTP request resulted in an response containing an error.
        this._status = cause.response.status;
        this._code = cause.response.data?.error_code;
        this._message = cause.response.data?.error_message;
        this._explanation = cause.response.data?.error_explanation;
      } else if (cause.request) {
        // An HTTP request did not receive a response in within the timeout
        // threshold.
        this._code = ErrorCodes.Timeout;
      } else {
        // Something else happened.
        this._code = ErrorCodes.Unknown;
      }

    }

    this._status = args?.status ?? this._status;
    this._code = args?.code ?? this._code;
    this._message = args?.message ?? this._message;
    this._explanation = args?.explanation ?? this._explanation;

  }

  /**
   * The HTTP status code, as applicable.
   * @return {number}
   */
  status() {
    return this._status;
  }

  /**
   * The error code, that indicates the type of error that occurred. This can
   * be used to provide a descriptive, localized error to the user or to
   * perform some contextualized action in response to the error.
   * @return {string}
   */
  code() {
    return this._code
  };

  /**
   * A simple message describing the error that occurred. This is mostly
   * useful for developers but may also act as a fallback description to
   * display to the user if no localized message can be determined.
   * @return {string}
   */
  message() {
    return this._message;
  }

  /**
   * An explanation for the error in free form. This may simply be an english
   * message or a structure that describes what went wrong. This is mostly
   * useful to developers. {@link #code()} is more useful for programmatic use
   * cases.
   * @return {*}
   */
  explanation() {
    return this._explanation
  };

  /**
   * The original error entity, as applicable.
   * @return {Object}
   */
  cause() {
    return this._cause
  };

}
