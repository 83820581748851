import React, {Component, useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import DensityTableComponent from "./DensityTableComponent";
import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Density = props => {

  const classes = useStyles();

  const {session} = useSelector(state => ({
    session: state.session.user?.access,
  }));

  return (
    <div className={classes.root}>
      {session
      .filter(property => property.remoteFunAvailFlag === true
      ).map(filteredProperty => {
        return (
            <DensityTableComponent propertyCode={filteredProperty.propCode}
                                   propertyName={filteredProperty.propName}
                                   propertySiteCode={filteredProperty.siteCode}
                                   propertyId={filteredProperty}/>)
      })}
    </div>);

};

export default Density;