/*
 * Created by Paul Engelke on 18 November 2021.
 */

import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import GlobalMessengerActionTypes
  from "../constants/action-types/globalMessengerActionTypes";

const DEFAULT_STATE = {queue: []};

/**
 * A redux state reducer for global messages, that will be displayed in a
 * universal snackbar.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case GlobalMessengerActionTypes.Enqueue:
      return enqueue(state, action);

    case GlobalMessengerActionTypes.Dequeue:
      return dequeue(state, action);

    case GlobalActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Enqueues a new message to end of the global message queue.
 */
const enqueue = (state, action) => {
  return {...state, queue: [...state.queue, action.message]};
};

/**
 * Dequeues the first message in the global message queue.
 */
const dequeue = (state) => {
  return {...state, queue: state.queue.slice(1)};
};
