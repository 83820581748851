import React, {useMemo} from 'react'
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
} from "@material-ui/core";
import * as DateUtil from "../../utils/DateUtil";
import {Tooltip} from "react-tippy";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  toolBar: {
    display: 'flex'
  },
  flex: {
    flex: 1,
  },
  areaLocation: {
    // paddingTop: 8,
    // paddingLeft: 10
    paddingLeft: 10,
  },
  chip: {
    '&:last-child': {
      marginLeft: 5,
    }
  },
  pmsAvailOutDated: {
    color: 'red'
  },
  toolTip: {
    display: 'flex !important',
    // width: '100%'
  },
});

const DensityHeadingComponent = props => {
  const classes = useStyles();

  const {propertyCode, propertyId} = props;

  return (
    <div className={classes.root}>
      <Toolbar>
        <Tooltip trigger="mouseenter" className={classes.toolTip}
                 size='regular'
                 arrow={true}
                 theme='light'
                 position='top'
                 useContext>
          <Typography variant="h5" color="inherit">
            {propertyId.propName}
          </Typography>
          <div className={classes.areaLocation}>
            <Chip
                className={classes.chip}
                label={propertyCode}
                variant={"default"}/>
          </div>
        </Tooltip>
        <div className={classes.flex} />
      </Toolbar>
    </div>
      );

};

export default DensityHeadingComponent;