/*
 * Created by Paul Engelke on 08 December 2021.
 */

/**
 * A set of all possible user rights for NebulaONE.
 */
const UserRights = Object.freeze({

  // todo
  //  * These can be objects or simple strings.
  //  * meta-data considerations:
  //  ** Show or hide user rights from the UI?
  //  ** What about how user rights can be grouped into categories for readability.
  //  ** Or do we store such information on the server?

  InstanceRead: "userRight_instanceRead",
  InstanceWrite: "userRight_instanceWrite",

});

export default UserRights;
