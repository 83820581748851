/*
 * Created by Paul Engelke on 17 November 2021.
 */

import SessionActionTypes from "../constants/action-types/sessionActionTypes";
import Http from "../core/http/http";
import {LocalCacheRegistry} from "@hti-ui/local-cache";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import UserRole from "../core/security/userRole";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";

/**
 * Authenticates a user and initializes their session state.
 * @return {function(*,*): Promise}
 */
export const signIn = () => async dispatch => {

  try {

    dispatch({type: SessionActionTypes.Request});

    const response = await Http.request({
      method: Http.Method.POST,
      withCredentials: false,
      url: "/v1/auth/basic",
      data: {
        username: "ben@hti-systems.co.za",
        password: "Password123",
      },
    });

    const user = response.data.user;
    const token = response.data.token;

    Http.setToken(token);
    dispatch({type: SessionActionTypes.SetUser, user});

    return response;

  } catch (e) {
    Http.setToken(null);
    dispatch({type: SessionActionTypes.FailRequest});
    throw e;
  }

}

export const signInHTIAuth = (idToken) => async dispatch => {

  try {

    dispatch({type: SessionActionTypes.Request});

    const response = await Http.request({
      method: Http.Method.POST,
      // withCredentials: false,
      url: "/v1/auth/hti_auth",
      data: {token: idToken},
    });

    const user = response.data.user;
    const token = response.data.token;

    Http.setToken(token);
    dispatch({type: SessionActionTypes.SetUser, user});

    return response;

  } catch (e) {
    Http.setToken(null);
    dispatch({type: SessionActionTypes.FailRequest});
    throw e;
  }

};

/**
 * Signs the current user out of the app.
 * @return {function(*,*): Promise}
 */
export const signOut = () => async dispatch => {

  // todo:
  //  This process is a mock implementation until the authentication
  //  framework has been properly established.
  const firebaseAuth = getAuth(getApp("auth"));

  if (!firebaseAuth) {
    return;
  }

  try {
    await firebaseAuth.signOut();
  } catch (error) {
    console.error("Problem clearing HTI Auth credentials", error);
  }

  dispatch({type: SessionActionTypes.Request});

  Http.setToken(null);
  LocalCacheRegistry.clear();

  dispatch({type: SessionActionTypes.CompleteRequest});
  dispatch({type: GlobalActionTypes.Reset});

};
