/*
 * Created by Paul Engelke on 18 November 2021.
 */

import GlobalMessageTypes from "../constants/globalMessageTypes";
import GlobalMessengerActionTypes
  from "../constants/action-types/globalMessengerActionTypes";
import {CoreDate} from "@hti-ui/js-core";

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @param {Object} [options] Options that can be used to configure the global
 * snackbar.
 * @return {function(*,*): void}
 */
export const dispatchSuccessMessage = (message, options) =>
    enqueueGlobalMessage({
      description: message,
      options: options,
      type: GlobalMessageTypes.Success,
    });

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @param {Object} [options] Options that can be used to configure the global
 * snackbar.
 * @return {function(*,*): void}
 */
export const dispatchWarningMessage = (message, options) =>
    enqueueGlobalMessage({
      description: message,
      options: options,
      type: GlobalMessageTypes.Warning,
    });

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @param {Object} [options] Options that can be used to configure the global
 * snackbar.
 * @return {function(*,*): void}
 */
export const dispatchErrorMessage = (message, options) =>
    enqueueGlobalMessage({
      description: message,
      options: {
        duration: 60000,
        dismissible: true,
        ...(options ?? {}),
      },
      type: GlobalMessageTypes.Error,
    });

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @param {Object} [options] Options that can be used to configure the global
 * snackbar.
 * @return {function(*,*): void}
 */
export const dispatchInfoMessage = (message, options) =>
    enqueueGlobalMessage({
      description: message,
      options: options,
      type: GlobalMessageTypes.Info,
    });

/**
 * Adds the given message object to the global message queue.
 * @param {Object} message The message to queue.
 * @return {function(*,*): void}
 */
const enqueueGlobalMessage = message => dispatch =>
    dispatch({
      type: GlobalMessengerActionTypes.Enqueue,
      message: {
        ...message,
        timestamp: CoreDate.now(),
      }
    });

/**
 * Removes the oldest message from the global message queue.
 * @return {function(*,*): void}
 */
export const dequeueGlobalMessage = () => dispatch =>
    dispatch({type: GlobalMessengerActionTypes.Dequeue});
