import React from 'react'
import TableCell from "@material-ui/core/TableCell";
import * as DateUtil from "../../utils/DateUtil";
import {
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: 0,
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      paddingRight: 0
    }
  },
  tableCellContentTop: {
    textAlign: 'center',
    marginTop: 6,
    marginBottom: 0
  },
  tableCellContentBottom: {
    textAlign: 'center',
    marginTop: 2,
    marginBottom: 6
  }
});

const DensityTableHeadCellComponent = props => {
  const classes = useStyles();

  const {doc} = props;

  return (
      <TableCell className={classes.root}>
        <p className={classes.tableCellContentTop}>
          {/*{*/}
          {/*  DateUtil.getDayOfWeek(DateUtil.localDateFromString(doc.forDate))*/}
          {/*}*/}
        </p>
        <p className={classes.tableCellContentBottom}>
          {/*{*/}
          {/*  DateUtil.localDateToString(*/}
          {/*      DateUtil.localDateFromString(doc.forDate), ' dd')*/}
          {/*}*/}
        </p>
      </TableCell>
);

};

export default DensityTableHeadCellComponent;