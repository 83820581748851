/*
 * Created by Paul Engelke on 25 March 2021.
 */

import {AbstractModel} from "./abstractModel";

/**
 * A model for storing the user's app appearance preferences.
 */
export default class AppAppearanceModel extends AbstractModel {

  constructor() {
    super('app-appearance');
  }

  serialize(value) {
    return JSON.stringify(value);
  }

  deserialize(value) {
    return JSON.parse(value);
  }

}
