/* 
 * Created by Paul Engelke on 10 May 2019.
 */

import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";

const styles = ({spacing}) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    padding: spacing(2),
    paddingBottom: 0,
  },
});

/**
 * A component for wrapping page content. This component should be contained in
 * a {@link Page} component, usually along with {@link PageToolbar}.
 *
 * @return {*}
 * @constructor
 */
const PageContent = ({classes, children}) => {
  return (<div className={classes.wrapper}>
    {children}
  </div>);
};

PageContent.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(PageContent);
