/* 
 * Created by Paul Engelke on 10 May 2019.
 */

import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {Typography, withStyles} from "@material-ui/core";
import Palette from "../styles/palette";

const styles = () => ({
  toolbar: {
    width: '100%',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  subtitle: {
    width: '100%',
  },
});

/**
 * A toolbar component for {@link Page}. It supports a standardised layout with
 * font styling for a title, subtitle/description and general page actions.
 *
 * @return {*}
 * @constructor
 */
const PageToolbar = ({
  theme: {spacing},
  classes,
  title,
  subtitle,
  variant = 'page-level',
  children
}) => {

  const isPageLevel = variant === 'page-level';

  return (<div
      className={classes.toolbar}
      style={isPageLevel ? {
        boxShadow: `0px 8px 20px 0px ${Palette.LIGHT_GREY}`,
        zIndex: 2,
        padding: spacing(2),
        paddingBottom: 0,
      } : {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      }}
  >
    <div
        className={classes.topRow}
        style={isPageLevel ? {
          minHeight: 64,
        } : {
          minHeight: 48,
        }}
    >
      {title && <Typography
          variant={variant === 'page-level' ? 'h5' : 'h6'}
          style={isPageLevel ? {} : {fontWeight: 400,}}
      >{title}</Typography>}
      {children && <div className={classes.actions}>{children}</div>}
    </div>
    {subtitle && <Typography
        className={classes.subtitle}
        variant={'subtitle1'}
        color={'textSecondary'}
    >{subtitle}</Typography>}
  </div>);
};

PageToolbar.propTypes = {

  theme: PropTypes.object,
  classes: PropTypes.object,

  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,

  variant: PropTypes.oneOf(['page-level', 'content-level']),
};

export default withStyles(styles, {withTheme: true})(PageToolbar);
