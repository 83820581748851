/*
 * Created by Paul Engelke on 29 November 2021.
 */

import {useSelector} from "react-redux";

/**
 * Provides the current app appearance, such as what them is currently in user.
 * @return {Object}
 */
const useAppAppearance = () =>
    useSelector(state => state.appAppearance);

export default useAppAppearance;
