/*
 * Created by Paul Engelke on 18 January 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the licence redux state.
 */
const LicenceActionTypes = ActionTypeUtility.createActionTypes("Licence", {
  Request: "Request",
  FailRequest: "FailRequest",
  CompleteRequest: "CompleteRequest",
  Set: "Set",
  Merge: "Merge",
});

export default LicenceActionTypes;
