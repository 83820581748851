/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {createTheme} from "@hti-ui/react-web-material";
import Palette from "./palette";

/**
 * The light mode theme for the app.
 */
const Theme = createTheme({
  baseColor: Palette.white,
  complimentColor: Palette.charcoal,
  secondaryColor: Palette.secondary,
  customColors: Palette,
});

export default Theme;
