/*
 * Created by Paul Engelke on 18 November 2021.
 */

import {useHistory} from "react-router-dom";
import {useActions} from "@hti-ui/redux-core";
import {useCallback} from "react";
import {
  dispatchSuccessMessage,
  dispatchInfoMessage,
  dispatchWarningMessage,
  dispatchErrorMessage
} from "../actions/globalMessengerActions";
import {signOut} from "../actions/sessionActions";

/**
 * A hook that provides easy access to the global message action creators,
 * as well as a useful error handler.
 *
 * @return {{
 *  handleError: (function(error: *, fallback?: string|function(*)): void),
 *  dispatchInfoMessage: function(message:string,options?:Object): void,
 *  dispatchWarningMessage:function(message:string,options?:Object): void,
 *  dispatchSuccessMessage: function(message:string,options?:Object): void,
 *  dispatchErrorMessage: function(message:string,options?:Object): void,
 * }}
 */
const useGlobalMessenger = () => {

  const history = useHistory();

  const [
    dispatchSuccess,
    dispatchInfo,
    dispatchWarning,
    dispatchError,
    _signOut,
  ] = useActions([
    dispatchSuccessMessage,
    dispatchInfoMessage,
    dispatchWarningMessage,
    dispatchErrorMessage,
    signOut,
  ]);

  const handleError = useCallback((error, fallback) => {

    if (error._code && error._message) {
      dispatchError(error._message);
    } else if (typeof fallback === "string") {
      dispatchError(fallback);
    } else if (typeof fallback === "function") {
      fallback(error);
    } else {
      dispatchError("An error occurred.");
    }

  }, [dispatchError, _signOut, history]);

  return {
    dispatchSuccessMessage: dispatchSuccess,
    dispatchInfoMessage: dispatchInfo,
    dispatchWarningMessage: dispatchWarning,
    dispatchErrorMessage: dispatchError,
    handleError,
  };

};

export default useGlobalMessenger;
