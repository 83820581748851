/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {useCallback, useMemo} from "react";
import {useHistory} from "react-router-dom";
import DrawerMenuItem from "../NavigationDrawer/DrawerMenuItem";
import {useSelector} from "react-redux";
import useAuthorizationCallback
  from "../../../hooks/useAuthorizationCallback";
import RouteNames from "../../../constants/routeNames";
import {
  DashboardOutlined as DashboardIcon,
  SecurityRounded as Icon,
  Assessment,
  KingBed,
  CalendarToday,
  TableChart,
  TimelineSharp
} from '@material-ui/icons';
import SystemAdminNode from "./systemAdminNode";
import CustomerAccountNode from "./customerAccountNode";

/**
 * A hook that provides the navigation tree available to the current user.
 */
const useNavigationTree = () => {
  const fullTree = useFullTree();
  const pruneTree = useTreePruner();
  return useMemo(() => pruneTree(fullTree), [fullTree, pruneTree]);
};

export default useNavigationTree;

/**
 * A hook that provides the entire navigation tree.
 */
const useFullTree = () => {

  const history = useHistory();
  const goTo = useCallback((route) => () => history.push(route), [history]);

  return useMemo(() => ({
    children: [

      {
        id: RouteNames.DashboardPage,
        label: 'Reports',
        onClick: goTo(RouteNames.DashboardPage),
        quickLink: {IconComponent: Assessment},
        renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
      },
      // {
      //   id: RouteNames.ReportsPage,
      //   label: 'Reports',
      //   onClick: goTo(RouteNames.ReportsPage),
      //   quickLink: {IconComponent: Assessment},
      //   renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
      // },
      // {
      //   id: RouteNames.RoomAvailability,
      //   label: 'Room Availability',
      //   onClick: goTo(RouteNames.RoomAvailability),
      //   quickLink: {IconComponent: KingBed},
      //   renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
      // },
      // {
      //   id: RouteNames.BanquetingAvailabilityPage,
      //   label: 'Banqueting Availability',
      //   onClick: goTo(RouteNames.BanquetingAvailabilityPage),
      //   quickLink: {IconComponent: TableChart},
      //   renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
      // },
      // {
      //   id: RouteNames.Chart,
      //   label: 'Chart',
      //   onClick: goTo(RouteNames.Chart),
      //   quickLink: {IconComponent: TimelineSharp},
      //   renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
      // },
      // CustomerAccountNode({goTo}),
      // SystemAdminNode({goTo}),

    ],
  }), [goTo]);

};

/**
 * Evaluates a navigation tree using security criteria and removes any
 * inaccessible nodes.
 */
const useTreePruner = () => {

  const user = useSelector(state => state.session.user);
  const isUserAuthorized = useAuthorizationCallback();

  /**
   * Checks if the user meets the requirements to access the node.
   */
  const isAuthorized = useCallback((node) => {

    const security = node?.security;
    const rights = security?.rights ?? [];
    if (!rights.length) {
      // No user rights are required to access the node.
      return true;
    }

    return isUserAuthorized({rights, all: security?.requireAllRights ?? true});

  }, [user, isUserAuthorized]);

  return useCallback((root) => {

    /**
     * A recursive function for pruning nodes and/or their children where the
     * user cannot access those nodes.
     *
     * @param {Object} node The current node to be evaluated.
     * @return {Object|null} The node, pruned of inaccessible children,
     * or null if the entire node was pruned.
     */
    const evalNode = (node) => {

      const hasRoute = !!node?.onClick;

      const _children = [];
      node?.children?.forEach(c => {
        const proposed = evalNode(c);
        if (proposed) {
          _children.push(proposed);
        }
      });

      return isAuthorized(node) && (hasRoute || _children.length > 0)
          ? {...node, children: _children}
          : null;

    };

    return evalNode(root) ?? {children: []};

  }, [isAuthorized]);

};
