/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {ComponentContextProvider} from '@hti-ui/react-web-material';
import {useActions} from "@hti-ui/redux-core";
import {useMediaQuery} from "@material-ui/core";
import {Provider as ReduxProvider} from 'react-redux';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import RouteNames from "../constants/routeNames";
import useTheme from "../hooks/useTheme";
import store from "../store";
import App from "./App";
import GlobalSnackbar from "./GlobalSnackbar";
import LoginPage from "./LoginPage";
import PrivateRoute from "./PrivateRoute";

/**
 * The entry point for the app.
 *
 * It is responsible for providing global context to the rest of the app.
 */
const Base = () => {

  const theme = useTheme();

  return (<ComponentContextProvider theme={theme}>
    <>

      <Router>
        <Switch>

          <Route path={RouteNames.LoginPage} component={LoginPage}/>

          <PrivateRoute path={RouteNames.App} component={App}/>

        </Switch>
      </Router>

      <GlobalSnackbar/>

    </>
  </ComponentContextProvider>);

};

/**
 * A higher order component that provides the redux store context. This allows
 * Root to determine the correct theme to inject based on the theme redux state.
 */
const withReduxProvider = (Component) => () =>
    (<ReduxProvider store={store}>
      <Component/>
    </ReduxProvider>);

export default withReduxProvider(Base);
