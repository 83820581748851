/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {Page} from "@hti-ui/react-web-material";
import {useActions} from "@hti-ui/redux-core";
import React, {useCallback} from 'react';
import {useHistory} from "react-router-dom";
import {signInHTIAuth} from "../../actions/sessionActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import {AuthUI} from "@hti-auth/react-web"
import RouteNames from "../../constants/routeNames";
import logo from "../../assests/images/png/apex-night-reports-logo22.png"
import Header from "./Header";

const Index= () => {

  const history = useHistory();
  const {handleError} = useGlobalMessenger();
  const _signIn = useActions(signInHTIAuth);
  const onSignInSuccess = useCallback(({idToken}) => {
    _signIn(idToken)
    .then(() => history.push(RouteNames.DashboardPage))
    .catch(e => handleError(e, "An error occurred during authentication."));
  }, [_signIn, history])


  return (<Page>

    <Header/>

    <AuthUI
        appLogo={logo}
        logout={false}
        onSignInSuccess={onSignInSuccess}
    />
  </Page>);

};

export default Index;
