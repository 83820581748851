/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {makeStyles} from "@material-ui/core";

/**
 * The navigation drawer footer component.
 */
const Footer = () => {
  const classes = useStyles();
  return (<div className={classes.root}>

    <a target="_blank" rel="noreferrer"
       href="https://www.htihospitality.tech"
       className={classes.link}
    >Developed By HTI</a>

    <p>|</p>

    <a target="_blank" rel="noreferrer"
       href="https://helpdesk.htihospitality.tech/portal/en/kb/articles/contact-hti-support"
       className={classes.link}
    >Support</a>

  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {

    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(.5),

    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,

  },
  link: {
    margin: theme.spacing(.5),
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {textDecoration: "underline"},
  },
}));

export default Footer;
