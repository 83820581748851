/*
 * Created by Paul Engelke on 29 November 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the workspace redux state.
 */
const WorkspaceActionTypes = ActionTypeUtility.createActionTypes(
    "Workspace",
    {
      Request: "Request",
      CompleteRequest: "CompleteRequest",
      FailRequest: "FailRequest",
      SetInstance: "SetInstance",
      SetCustomer: "SetCustomer",
      SetProperty: "SetProperty",
      SetCustomerLicence: "SetCustomerLicence",
      SetPropertyLicence: "SetPropertyLicence",
    },
);

export default WorkspaceActionTypes;
