/*
 * Created by Paul Engelke on 17 November 2021.
 */

import PropTypes from "prop-types";
import {useMemo} from "react";
import {HelpOutline as UnknownIcon} from '@material-ui/icons';
import {IconButton} from "@material-ui/core";
import {NavigationBarButtonGroup, Tooltip} from "@hti-ui/react-web-material";

const QuickLinks = props => {

  const {tree, onQuickLinkClicked} = props;

  const links = useMemo(() => {

    const links = [];
    const stack = [tree];

    while (stack.length > 0) {

      const node = stack.shift();
      if (node.quickLink) {
        links.push({
          key: node.id,
          tooltip: node.label,
          IconComponent: node.quickLink.IconComponent ?? UnknownIcon,
          onClick: () => onQuickLinkClicked(node),
        });
      }

      if (node.children?.length > 0) {
        // Add the node's children to the stack for evaluation.
        stack.push(...node.children);
      }

    }

    return links;

  }, [tree, onQuickLinkClicked]);

  return (<NavigationBarButtonGroup>
    {links.map(link => (
        <Tooltip key={link.key} placement={'right'} content={link.tooltip}>
          <IconButton onClick={link.onClick}>
            <link.IconComponent/>
          </IconButton>
        </Tooltip>
    ))}
  </NavigationBarButtonGroup>);

};

QuickLinks.propTypes = {
  tree: PropTypes.object.isRequired,
  onQuickLinkClicked: PropTypes.func.isRequired,
};

export default QuickLinks;
