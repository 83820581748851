import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import DensityTableHeadCellComponent from "./DensityTableHeadCellComponent";
import TableCell from "@material-ui/core/TableCell";
import * as DateUtil from "../../utils/DateUtil";
import {
  makeStyles,
} from "@material-ui/core";
import Palette from "../../styles/colors";

const useStyles = makeStyles({
  dateRange: {
    height: 16,
  },
  dateRangeCell: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 14,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  endRange: {
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: 'right',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  dateHead: {
    backgroundColor: '#F4F4F4',
    height: 44,
  },
  innerRange: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 14,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  inventoryCell: {
    paddingTop: 6
  }
});

const getPrettyDate = (forDate) => {
  const localDate = DateUtil.localDateFromString(forDate);
  return DateUtil.getMonthOfYear_full(localDate)
      + ' ' + DateUtil.localDateToString(localDate, 'yyyy')
};

const DensityTableHeadComponent = props => {
  const classes = useStyles();
  let dateCells = [];

  const {roomTypes} = props;
  const availability = roomTypes

  availability.forEach((doc, index) => {
    dateCells.push(<DensityTableHeadCellComponent key={index} doc={doc} />);
  });

  return (
      [
        // <TableRow key={1} className={classes.dateRange}>
        //   <TableCell className={classes.dateRangeCell} >{''}</TableCell>
        //   <TableCell colSpan={7} className={classes.dateRangeCell}>
        //
        //   </TableCell>
        //   <TableCell colSpan={6} className={classes.innerRange}>
        //
        //   </TableCell>
        //   <TableCell colSpan={8} className={classes.endRange}>
        //
        //   </TableCell>
        // </TableRow>,
        <TableRow key={2} className={classes.dateHead}>
          <TableCell className={classes.inventoryCell}>Rooms</TableCell>
          {dateCells}
        </TableRow>
      ]
  );

};

export default DensityTableHeadComponent;