/*
 * Created by Benneth on 23 June 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the property redux state.
 */
const BanquetingAvailabilityTypes = ActionTypeUtility.createActionTypes(
    "Banqueting",
    {
      Request: "Request",
      FailRequest: "FailRequest",
      Set: "Set",
    },
);

export default BanquetingAvailabilityTypes;
