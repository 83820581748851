/*
 * Created by Paul Engelke on 17 November 2021.
 */

/**
 * A defined set or navigation routes for the app.
 */
const RouteNames = Object.freeze({

  SignInPage: "/sign-in",
  LoginPage: "/sign-in",

  App: "/",
  DashboardPage: '/dashboard',
  ReportsPage: '/reports',
  // BanquetingAvailability: '/banqueting',
  RoomAvailability: '/room',

  AccountInformationPage: "/account-info",
  PropertyPage: "/properties",
  RoomPage: "/rooms",

  UserPage: "/users",
  UserRoles: "/user-roles",

  InstancePage: "/instances",
  CustomerPage: "/customers",
  LicencePage: "/licences",
  BanquetingAvailabilityPage: '/banqueting-table',
  Chart: '/chart',

});

export default RouteNames;
