/*
 * Created by Paul Engelke on 25 March 2021.
 */

import {AbstractModel} from "./abstractModel";

/**
 * A model for storing the user's session token in local storage.
 */
export default class JwtTokenModel extends AbstractModel {

  constructor() {
    super('jwt-token');
  }

  serialize(value) {
    return value;
  }

  deserialize(value) {
    return value;
  }

}
