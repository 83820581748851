/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {useMemo} from "react";
import useSession from "./useSession";
import useWorkspace from "./useWorkspace";

/**
 * Checks if the current user has all the user rights in the given list.
 *
 * @param {Object[]} rights A set of user rights that the current user requires
 * to gain access to some feature. This must remain fixed as it forms part of
 * the memoization hook's dependencies.
 * @param {boolean} [all=true] If true, then the user must be authorized for
 * all specified user rights. If false, then the user only needs authorization
 * for at least one of the rights.
 * @return {boolean}
 * @see useAuthorizationCallback
 */
const useAuthorizationFlag = (rights, all = true) => {

  const session = useSession();
  const user = session.user;
  const userRole = session.userRole;

  const workspace = useWorkspace();
  const customerLicence = workspace.customerLicence;
  const propertyLicence = workspace.propertyLicence;

  const rightHash = rights?.join("-");

  return useMemo(() => {
    // todo determine if the user is authorized to perform an action or
    //  access a part of the app.
    return true;
  }, [user, userRole, customerLicence, propertyLicence, rightHash, all]);

};

export default useAuthorizationFlag;
