/*
 * Created by Paul Engelke on 18 January 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import LicenceActionTypes from "../constants/action-types/licenceActionTypes";

const DEFAULT_STATE = StateUtility.State(
    {data: []},
    {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A redux state reducer for licence data.
 */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case LicenceActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case LicenceActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case LicenceActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);

    case LicenceActionTypes.Set:
      return StateUtility.defaultSet(state, action.data);

    case LicenceActionTypes.Merge:
      return StateUtility.defaultSet(
          state,
          StateUtility.mergeData(
              state.data,
              action.data,
              e => e.licence_uuid,
          ),
      );

    case GlobalActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;

  }
}
