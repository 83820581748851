/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {useEffect, useState} from "react";
import SessionStatuses from "./sessionStatuses";
import useSession from "../../hooks/useSession";
import {useActions} from "@hti-ui/redux-core";
import {signIn} from "../../actions/sessionActions";

/**
 * Checks the user's session status and provides session initialization where
 * there exists a valid JWT token.
 * @return {string}
 * @see SessionStatuses
 */
const useSessionStatus = () => {

  const authenticated = !!useSession().user;
  const _signIn = useActions(signIn)

  const initialStatus = authenticated
      ? SessionStatuses.Authenticated
      : SessionStatuses.Pending;

  const [sessionValid, setSessionValid] = useState(initialStatus);
  useEffect(() => {
    if (!authenticated) {
      _signIn()
      .then(() => setSessionValid(SessionStatuses.Authenticated))
      .catch(() => setSessionValid(SessionStatuses.NotAuthenticated));
    }
  }, [authenticated]);

  return sessionValid;

};

export default useSessionStatus;
