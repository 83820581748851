/*
 * Created by Paul Engelke on 17 November 2021.
 */

import SessionActionTypes from "../constants/action-types/sessionActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import {StateUtility} from "@hti-ui/redux-core";

const DEFAULT_STATE = StateUtility.State({
  user: null,
  userRole: null,
}, {async: true, asyncOptions: {useCounter: true}});

/**
 * A redux state reducer for the current session. The session contains
 * information about the currently signed-in user.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case SessionActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case SessionActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case SessionActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);

    case SessionActionTypes.SetUser:
      return StateUtility.defaultCompleteRequest({
        ...state,
        user: action.user,
      });

    case SessionActionTypes.SetUserRole:
      return StateUtility.defaultCompleteRequest({
        ...state,
        userRole: action.userRole,
      });

    case GlobalActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;
  }

};
