import React, {Component, useEffect, useState} from 'react'
import TableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import {Tooltip} from "react-tippy";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

const ToolTipHTML = ({data}) => {

  let classes = useStyles();

  const {name, description} = data;
  return (
      <React.Fragment>
        <div>
          <Typography variant={"h6"} gutterBottom>{name}</Typography>
        </div>
        <table className={classes.table}>
          <tbody>

          </tbody>
        </table>
        {description && <div className={classes.description}>
          <Typography variant={"subtitle2"}>Description:</Typography>
          <Typography variant={"caption"}>{description}</Typography>
        </div>}
      </React.Fragment>
  )
};

const InventoryCellComponent = props => {

  const classes = useStyles();
  const {name, description} = props;

  return (
      <TableCell>
        <Typography variant={"subtitle2"} className={classes.typography}>
          <Tooltip html={<ToolTipHTML
              data={{name, description}}/>}
                   position={"right"}
                   arrow={true}
                   theme={"light"}
          >
            {name}
          </Tooltip>
        </Typography>
      </TableCell>

  );

};

const useStyles = makeStyles({
  table: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 10,
  },
  td: {
    width: 150
  },
  description: {
    maxWidth: 300
  },
  typography: {
    fontSize: 'small'
  }
});


export default InventoryCellComponent;