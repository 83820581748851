/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the property redux state.
 */
const PropertyActionTypes = ActionTypeUtility.createActionTypes(
    "Property",
    {
      Request: "Request",
      FailRequest: "FailRequest",
      CompleteRequest: "CompleteRequest",
      Set: "Set",
    },
);

export default PropertyActionTypes;
