/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the app appearance.
 */
const AppAppearanceActionTypes = ActionTypeUtility.createActionTypes(
    "AppAppearance",
    {

      /**
       * Used to initialize the app appearance from web browser persistence.
       */
      Init: "Init",

      /**
       * Used to change the theme for the web app.
       */
      SetTheme: "SetTheme",

    },
);

export default AppAppearanceActionTypes;
