/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {useSelector} from "react-redux";
import DarkTheme from "../styles/darkTheme";
import LightTheme from "../styles/lightTheme";
import ThemeTypes from "../constants/themeTypes";

/**
 * Provides the currently applicable UI theme.
 * @return Object
 */
const useTheme = () => {
  const _theme = useSelector(state => state.appAppearance.theme);
  return _theme === ThemeTypes.Dark ?  DarkTheme : LightTheme;
};

export default useTheme;
