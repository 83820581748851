/*
 * Created by Paul Engelke on 29 November 2021.
 */

import {useCallback, useState} from "react";
import {ButtonBase, makeStyles, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {StateUtility} from "@hti-ui/redux-core";
import useSession from "../../../../hooks/useSession";
import UserCard from "./UserCard";
import {Tooltip} from "@hti-ui/react-web-material";

/**
 * A button that identifies the current user. It can be clicked to see
 * additional information and actions.
 */
const UserWidget = () => {

  const classes = useStyles();
  const loading = useSelector(state => StateUtility.isBusy(state.session));
  const user = useSession().user;

  const firstName = user?.employeeName ?? "";
  const fullName = [firstName];
  const initials = [firstName].map(s => s.charAt(0)).join("");

  const [anchor, setAnchor] = useState(null);
  const _setAnchor = useCallback(e => setAnchor(e.currentTarget), []);
  const clearAnchor = useCallback(() => setAnchor(null), []);

  return (<>

    <Tooltip content={`Signed in as ${fullName}`} placement={"right"}>
      <ButtonBase
          className={classes.button}
          onClick={_setAnchor}
          disabled={loading}
      >
        <Typography>{initials}</Typography>
      </ButtonBase>
    </Tooltip>

    <UserCard
        anchor={anchor}
        onClose={clearAnchor}
        fullName={fullName}
        initials={initials}
    />

  </>);

};

const useStyles = makeStyles(theme => ({
  button: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.compliment['30%']}`,
  },
  buttonContent: {
    fontSize: 'larger',
    fontWeight: 500,
  },
}));

export default UserWidget;
