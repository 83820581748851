/*
 * Created by Paul Engelke on 29 November 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import WorkspaceActionTypes
  from "../constants/action-types/workspaceActionTypes";

const DEFAULT_STATE = StateUtility.State({

  /**
   * An instance denotes a group of customers.
   * This is used to give context to certain screens that affect all customers
   * within an instance.
   */
  instance: null,

  /**
   * A customer denotes an HTI client for this product.
   * This is used to give context to screens that affect any customer specific
   * data.
   */
  customer: null,

  /**
   * A property represents a physical establishment such as a hotel.
   * This is used to give context to screens that affect the data of a specific
   * property.
   */
  property: null,

  /**
   * A customer licence is one that describes the accessible feature set at the
   * customer level.
   * Some screens may wish query this instead of a more specific licence, or
   * where no lower level licence is applicable.
   * This is tied to the value of "customer".
   */
  customerLicence: null,

  /**
   * A property licence is one that describes the accessible feature set at the
   * property level. This may inherit attributes from a more generic customer
   * licence.
   * Some screens may wish query this instead of the customer licence where a
   * specific property's context is required.
   * This is tied to the value of "property".
   */
  propertyLicence: null,

}, {async: true, asyncOptions: {useCounter: true}});

/**
 * A redux reducer for the global workspace state.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case WorkspaceActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case WorkspaceActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);

    case WorkspaceActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case WorkspaceActionTypes.SetInstance:
      return StateUtility.defaultCompleteRequest({
        ...state,
        instance: action.instance,
        customer: null,
        customerLicence: null,
        property: null,
        propertyLicence: null,
      });

    case WorkspaceActionTypes.SetCustomer:
      return StateUtility.defaultCompleteRequest({
        ...state,
        customer: action.customer,
        customerLicence: action.licence,
        property: null,
        propertyLicence: null,
      });

    case WorkspaceActionTypes.SetProperty:
      return StateUtility.defaultCompleteRequest({
        ...state,
        property: action.property,
        propertyLicence: action.licence,
      });

    case WorkspaceActionTypes.SetCustomerLicence:
      return StateUtility.defaultCompleteRequest({
        ...state,
        customerLicence: action.customerLicence,
      });

    case WorkspaceActionTypes.SetPropertyLicence:
      return StateUtility.defaultCompleteRequest({
        ...state,
        propertyLicence: action.propertyLicence,
      });

    case GlobalActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
