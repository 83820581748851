/*
 * Created by Paul Engelke on 30 November 2021.
 */

import {useSelector} from "react-redux";

/**
 * Provides the current global workspace context.
 * @return {Object}
 */
const useWorkspace = () => useSelector(state => state.workspace);
export default useWorkspace;
