import RoomAvailabilityTypes from '../constants/action-types/roomAvailabilityActionTypes'
import {StateUtility} from "@hti-ui/redux-core";

const DEFAULT_STATE = StateUtility.State({
  room: [],
},{async: true,});

export default (state = DEFAULT_STATE, action) => {
  switch (action.type){
    case RoomAvailabilityTypes.Request:
      return StateUtility.defaultRequest(state);

    case RoomAvailabilityTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case RoomAvailabilityTypes.Set:
      return StateUtility.defaultCompleteRequest({
        ...state,
        room: action.data,
      });

    default:
      return state;
  }
}