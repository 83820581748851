import {ActionTypeUtility} from "@hti-ui/redux-core";

const ClientsActionType = ActionTypeUtility.createActionTypes(
    "Clients",
    {
      Request: "Request",
      FailRequest: "FailRequest",
      Set: "Set",
    },
);

export default ClientsActionType