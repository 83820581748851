/*
 * Created by Paul Engelke on 20 Nov 2018.
 */

import Color from 'color';

const Colors = {

  TURQUOISE: '#3bc0a8',

  BLUE: '#1a80b6',
  GREEN: '#138025',
  RED: '#b31b1b',
  PURPLE: '#7b47b3',
  LIGHT_PURPLE: '#707CD2',
  RASPBERRY: '#e64c66',
  DEEP_ORANGE: '#ff5722',

  WHITE: '#fff',
  LIGHT_GREY: '#eceff1',
  // LIGHT_GREY: '#fafafa',

  GREY: '#bdbdbd',
  CHARCOAL: '#383838',
  CHARCOAL_LIGHT: '#626262',
  CHARCOAL_DARK: '#121212',
  // CHARCOAL: '#4d4d4d',
  // CHARCOAL_LIGHT: '#797979',
  // CHARCOAL_DARK: '#252525',

  BLACK: '#000',

  GOOGLE_BLUE: '#0057e7',
  GOOGLE_YELLOW: '#ffa700',
  GOOGLE_GREEN: '#008744',
  GOOGLE_RED: '#d62d20',

  FACEBOOK_BLUE: '#39579b',

  TRANSPARENT: 'transparent',

  // GUARANTEED: '#DC6973',
  // PROVISIONAL: '#6BAC5F',
  // HOLD: '#7DB9D3',
  // CANCELLED: '#ffee58',

  GUARANTEED: '#6BAC5F',
  PROVISIONAL: '#7DB9D3',
  HOLD: '#f57c00',//'#ffee58',
  CANCELLED: '#DC6973',

  SHADOWS: [
    'none',
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
    '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)'
  ],

  get GUARANTEED_LIGHT() {
    return Color(this.GUARANTEED).lighten(0.5).toString();
  },

  get GUARANTEED_30_PERCENT() {
    return Color(this.GUARANTEED).fade(0.7).toString();
  },

  get PROVISIONAL_LIGHT() {
    return Color(this.PROVISIONAL).lighten(0.5).toString();
  },

  get PROVISIONAL_30_PERCENT() {
    return Color(this.PROVISIONAL).fade(0.7).toString();
  },

  get HOLD_LIGHT() {
    return Color(this.HOLD).lighten(0.5).toString();
  },

  get HOLD_30_PERCENT() {
    return Color(this.HOLD).fade(0.7).toString();
  },

  get CANCELLED_LIGHT() {
    return Color(this.CANCELLED).lighten(0.5).toString();
  },

  get CANCELLED_30_PERCENT() {
    return Color(this.CANCELLED).fade(0.7).toString();
  },

  get CHARCOAL_10_PERCENT() {
    return Color(this.CHARCOAL).fade(0.9).toString();
  },

  get CHARCOAL_20_PERCENT() {
    return Color(this.CHARCOAL).fade(0.8).toString();
  },

  get CHARCOAL_30_PERCENT() {
    return Color(this.CHARCOAL).fade(0.7).toString();
  },

  get DISABLED() {
    return Color(this.CHARCOAL).fade(0.74).toString();
  },
};

export default Colors;
