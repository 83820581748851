import ViewReportActionTypes from '../constants/action-types/viewReportActionType'
import {StateUtility} from "@hti-ui/redux-core";

const DEFAULT_STATE = StateUtility.State({
  display: [],
},{async: true,});

export default (state = DEFAULT_STATE, action) => {
  switch (action.type){
    case ViewReportActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case ViewReportActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case ViewReportActionTypes.Set:
      return StateUtility.defaultCompleteRequest({
        ...state,
        display: action.data,
      });

    default:
      return state;
  }
}