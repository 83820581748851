/*
 * Created by Paul Engelke on 17 November 2021.
 */

/**
 * An enumeration of supported themes for the app appearance.
 */
const ThemeTypes = Object.freeze({
  Light: "light",
  Dark: "dark",
});

export default ThemeTypes;
