import React, {Component, useEffect, useState} from 'react'
import InventoryCellComponent from "./InventoryCellComponent";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import {
  makeStyles,
} from "@material-ui/core";
import * as DateUtil from "../../utils/DateUtil";
import Palette from "../../styles/colors";
import {isEqual} from 'lodash';

const DensityTableRowComponent = props => {

  const classes = useStyles();
  const {propertyId, key, availability} = props;

  return (
      <TableRow>
        {
          availability.length > 0 && <InventoryCellComponent
              name={availability[0].roomTypeCode}
              description={availability[0].propertyCode}
/>
        }

      </TableRow>

    );

};

const useStyles = makeStyles({
  root: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: 0,
  },
  hover: {
    '&:hover': {
      background: '#d1d1d1',
    }
  },
  hoverGuar: {
    '&:hover': {
      background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.GUARANTEED} 30%)`,
    }
  },
  hoverProv: {
    '&:hover': {
      background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.PROVISIONAL} 30%)`,
    }
  },
  hoverHold: {
    '&:hover': {
      background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.HOLD} 30%)`,
    }
  },
  reserved: {
    background: '#bfbfbf'
  },
  reservedGuar: {
    background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.GUARANTEED} 30%)`
  },
  reservedHold: {
    background: `linear-gradient(135deg, #bfbfbf 85%, ${Palette.HOLD} 30%)`
  },
  reservedProv: {
    background: `linear-gradient(135deg, #bfbfbf 85%, ${Palette.PROVISIONAL} 30%)`,
  },
  selected: {
    background: '#d1d1d1'
  },
  selectedGuar: {
    background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.GUARANTEED} 30%)`
  },
  selectedProv: {
    background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.PROVISIONAL} 30%)`
  },
  selectedHold: {
    background: `linear-gradient(135deg, #d1d1d1 85%, ${Palette.HOLD} 30%)`
  },
  notSelected: {
    background: '#FFFFFF',
  },
  notSelectedGuar: {
    // background: 'white',
    background: `linear-gradient(135deg, #FFFFFF 85%, ${Palette.GUARANTEED} 30%)`,
  },
  notSelectedProv: {
    // background: 'white',
    background: `linear-gradient(135deg, #FFFFFF 85%, ${Palette.PROVISIONAL} 30%)`,
  },
  notSelectedHold: {
    // background: 'white',
    background: `linear-gradient(135deg, #FFFFFF 85%, ${Palette.HOLD} 30%)`,
  },
  highlight: {
    fontWeight: 500,
    fontSize: '1.3rem',

  },
  highlight_withAllocation: {
    fontSize: '1.15rem',
  },
  disabled: {
    opacity: 0.6,
    '&:hover': {
      background: 'white',
    },

  },
});

export default DensityTableRowComponent;