/*
 * Created by Paul Engelke on 29 November 2021.
 */

import {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import {useActions} from "@hti-ui/redux-core";
import {setTheme} from "../../../../actions/appAppearanceActions";
import {
  Avatar, CardContent,
  CardHeader,
  Divider,
  makeStyles,
  MenuItem,
  Popover,
  Typography
} from "@material-ui/core";
import useSession from "../../../../hooks/useSession";
import useSignOutCallback from "../../../../hooks/useSignOutCallback";
import {
  ExitToAppRounded as SignOutIcon,
  NightsStayOutlined as DarkModeIcon,
  WbSunnyOutlined as LightModeIcon
} from '@material-ui/icons';
import useAppAppearance from "../../../../hooks/useAppAppearance";
import ThemeTypes from "../../../../constants/themeTypes";

/**
 * A popover card that describes the signed-in user and provides some actions,
 * such as signing out.
 */
const UserCard = props => {

  const classes = useStyles();
  const {anchor, onClose, fullName, initials} = props;

  const session = useSession();
  const customerName = session.user?.employeeSiteCode;

  const userRole = session.userRole;
  const userRoles = useMemo(() => {
    const roles = userRole
    ?.getRoles()
    ?.map(role => role.userRole_name);
    return roles?.length > 1
        ? `${roles[0]} & ${roles.length - 1} other role(s)`
        : roles?.[0];
  }, [userRole]);

  const onSignOutClicked = useSignOutCallback();

  const theme = useAppAppearance().theme;
  const isLightMode = theme === ThemeTypes.Light;
  const _setTheme = useActions(setTheme);
  const toggleTheme = useCallback(() => {
    _setTheme({
      theme: isLightMode ? ThemeTypes.Dark : ThemeTypes.Light,
      persist: true,
    });
  }, [theme, isLightMode, _setTheme]);

  return (<Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      onClose={onClose}
  >

    <CardHeader
        title={fullName}
        subheader={userRoles}
        avatar={<Avatar className={classes.avatar}>{initials}</Avatar>}
    />

    <Divider/>

    {customerName && <CardContent>
      <Typography className={classes.customerName}>{customerName}</Typography>
    </CardContent>}

    {/*<Divider/>*/}

    {/*<MenuItem onClick={toggleTheme} className={classes.menuItem}>*/}
    {/*  {isLightMode ? (<DarkModeIcon/>) : (<LightModeIcon/>)}*/}
    {/*  <Typography className={classes.menuItemText}>*/}
    {/*    {isLightMode ? "Switch to Dark Mode" : "Switch to Light Mode"}*/}
    {/*  </Typography>*/}
    {/*</MenuItem>*/}

    {/*<Divider/>*/}

    <MenuItem onClick={onSignOutClicked} className={classes.menuItem}>
      <SignOutIcon/>
      <Typography className={classes.menuItemText}>Sign Out</Typography>
    </MenuItem>

  </Popover>);

};

const useStyles = makeStyles(theme => ({
  customerName: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  avatar: {
    backgroundColor: theme.palette.compliment["100%"],
    color: theme.palette.base["100%"],
  },
  menuItem: {padding: theme.spacing(2)},
  menuItemText: {marginLeft: theme.spacing()},
}))

UserCard.propTypes = {
  anchor: PropTypes.element,
  onClose: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  initials: PropTypes.string,
};

export default UserCard;
