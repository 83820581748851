/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {combineReducers} from "redux";
import appAppearanceReducer from "./appAppearanceReducer";
import sessionReducer from "./sessionReducer";
import globalMessengerReducer from "./globalMessengerReducer";
import workspaceReducer from "./workspaceReducer";
import instanceReducer from "./instanceReducer";
import customerReducer from "./customerReducer";
import propertyReducer from "./propertyReducer";
import licenceReducer from "./licenceReducer";
import reportReducer from "./reportReducer";
import clientsReducer from "./clientsReducer"
import displayReportReducer from "./displayReportReducer"
import roomAvailabilityReducer from "./roomAvailabilityReducer"
import banquetingAvailabilityReducer from "./banquetingAvailabilityReducer"

/**
 * The root reducer for our global redux state.
 */
export default combineReducers({

  appAppearance: appAppearanceReducer,
  globalMessenger: globalMessengerReducer,
  session: sessionReducer,
  workspace: workspaceReducer,

  licences: licenceReducer,

  instances: instanceReducer,
  customers: customerReducer,
  properties: propertyReducer,
  reports: reportReducer,
  clients: clientsReducer,
  displayReport: displayReportReducer,
  rooms: roomAvailabilityReducer,
  banqueting: banquetingAvailabilityReducer

});
