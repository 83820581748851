/*
 * Created by Paul Engelke on 17 November 2021.
 */

import React from "react";
import {makeStyles} from "@material-ui/core";
import NavigationBar from "./NavigationBar";
import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import RouteNames from "../../constants/routeNames";
import DashboardPage from "../DashboardPage";
// import ReportsPage from "../../containers/ReportsPage"
import RoomAvailabilityPage from "../RoomAvailabilityPage";
import BanquetingAvailabilityPage from "../BanquetingAvailabilityPage"
import ChartPage from "../ChartPage"

/**
 * The app's base component responsible for dictating the layout of it's
 * primary components, i.e. the navigation bar and viewable content area.
 *
 * It is also responsible for defining navigable routes.
 */

const App = () => {

  const classes = useStyles();

  return (<div className={classes.root}>

    <NavigationBar/>

    <div className={classes.content}>
      <Switch>

        <PrivateRoute
            path={RouteNames.DashboardPage}
            component={DashboardPage}
        />
        {/*<Route path={RouteNames.RoomAvailability} component={RoomAvailabilityPage}/>*/}
        {/*<Route*/}
        {/*    exact*/}
        {/*    path={RouteNames.BanquetingAvailabilityPage}*/}
        {/*    component={BanquetingAvailabilityPage}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*    exact*/}
        {/*    path={RouteNames.Chart}*/}
        {/*    component={ChartPage}*/}
        {/*/>*/}
        <Redirect to={RouteNames.DashboardPage}/>

      </Switch>
    </div>

  </div>);

};

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  contentAndWorkspace: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
});

export default App;
