/*
 * Created by Paul Engelke on 30 November 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import InstanceActionTypes from "../constants/action-types/instanceActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State(
    {data: []},
    {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A redux reducer for instance data.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case InstanceActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case InstanceActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case InstanceActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);

    case InstanceActionTypes.Set:
      return StateUtility.defaultSet(state, action.data);

    case InstanceActionTypes.Merge:
      return merge(state, action);

    case InstanceActionTypes.Create:
      return create(state, action);

    case InstanceActionTypes.Update:
      return update(state, action);

    case InstanceActionTypes.Delete:
      return _delete(state, action);

    case GlobalActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

const merge = (state, action) => {
  const next = StateUtility.mergeData(
      state.data,
      action.data,
      i => i.instance_uuid,
  );
  return StateUtility.defaultSet(state, next);
};

const create = (state, action) => {
  const next = [...state.data, action.instance];
  return StateUtility.defaultSet(state, next);
};

const update = (state, action) => {
  const instance = action.instance;
  const next = state.data.map(i =>
      i.instance_uuid === instance.instance_uuid
          ? instance
          : i);
  return StateUtility.defaultSet(state, next);
};

const _delete = (state, action) => {
  const next = state.data.filter(i => i.instance_uuid !== action.id);
  return StateUtility.defaultSet(state, next);
};
