/*
 * Created by Paul Engelke on 17 November 2021.
 */

import React from "react";
import {makeStyles} from "@material-ui/core";

/**
 * A splash screen component for preventing a user from interacting with or
 * viewing the app.
 *
 * A common use case is session initialization on routing.
 */
const SplashScreen = () => {
  const classes = useStyles();
  return (<div className={classes.root}>
    {/*  todo Add splashscreen image.*/}
  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0, bottom: 0,
    left: 0, right: 0,
    backgroundColor: theme.palette.base['100%'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {height: 944 * .3, width: 835 * .3},
}));

export default SplashScreen;
