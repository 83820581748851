/*
 * Created by Paul Engelke on 30 November 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the instance redux state.
 */
const InstanceActionTypes = ActionTypeUtility.createActionTypes(
    "Instance",
    {

      Request: "Request",
      FailRequest: "FailRequest",
      CompleteRequest: "CompleteRequest",

      Set: "Set",
      Merge: "Merge",

      Create: "Create",
      Update: "Update",
      Delete: "Delete",

    },
);

export default InstanceActionTypes;
