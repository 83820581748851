/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const enhancers = [];
const middleware = [
  // Thunk will allow the "dispatch" and "getState" functions to be
  // injected into action creators.
  thunk,
];

// noinspection JSUnresolvedVariable
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (process.env.NODE_ENV === "development"
    && typeof devToolsExtension === "function") {
  // Enable the redux dev-tools plugin if we are in development mode and the
  // plugin is available.
  enhancers.push(devToolsExtension());
}

// noinspection JSCheckFunctionSignatures
const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

// The initial state is an empty object.
// All reducers are responsible for generating their initial state.
// This allows for better encapsulation and separation of concerns.
const initialState = {};

const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
);

export default store;
