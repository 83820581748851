/*
 * Created by Paul Engelke on 17 November 2021.
 */

import React from "react";
import PropTypes from "prop-types";
import {NavigationDrawer as HtiNavigationDrawer} from "@hti-ui/react-web-material";
import Header from "./Header";
import Footer from "./Footer";

/**
 * The primary navigation drawer for the app.
 */
const NavigationDrawer = props => (
    <HtiNavigationDrawer
        {...props}
        searchPlaceholder={"Search"}
        header={<Header/>}
        footer={<Footer/>}
    />
);

NavigationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navigationTree: PropTypes.object.isRequired,
  navigationState: PropTypes.object.isRequired,
  onNavigationStateChanged: PropTypes.func.isRequired,
};

export default NavigationDrawer;
