/*
 * Created by Benneth Nchabeleng on 18 May 2022.
 */
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
  LoadingIndicator,
  Page,
  PageContent,
  PageToolbar,
  DateField
} from "@hti-ui/react-web-material";
import {StateUtility, useActions} from "@hti-ui/redux-core";
import {useSelector} from "react-redux";
import {displayReport} from "../../actions/Report/viewReportAction"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import {CoreDate} from "@hti-ui/js-core";
import {
  Button,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  formControl: {
    marginTop: 50,
    width: '100%',
  },
  grid: {
    width: '100%',
    height: '50%'
  },
  button: {
    color: '#fff',
    marginLeft: 20,
    marginRight: 20,
    background: '#383838',
  },
  datePicker: {
    marginTop: 10,
  },
  radioGrid: {
    marginLeft: 5,
    height: '100%'
  },
  formControlLabel: {
    width: "30%",
    marginTop: 5,
    marginBottom: 10,
    background: '#f2f0f0',
    borderRadius: 15,
    boxShadow: ' 5px 5px 5px #e0dedf',
    '&:hover': {
      background: "#9e9b9b",
    },
  },
});

/**
 * The dashboard page is the first thing users will see when they sign in.
 * It displays various summary information in the form of widgets.
 */
const DashboardPage = () => {

  const classes = useStyles();
  const history = useHistory();
  const {handleError} = useGlobalMessenger();

  const loadingReport = useSelector(state => StateUtility.areBusy(
      state.displayReport,
  ));

  const {session} = useSelector(state => ({
    session: state.session.user?.access,
  }));

  const empSiteCode = useSelector(state => state.session.user?.employeeSiteCode);

  const [selectedReport, setReport] = useState('DEP');
  const [[selectedDate,], setSelectedDate] = useState(
      [CoreDate.today(), true]);

  const [user, setUser] = useState(0)

  const [_displayReport] = useActions([displayReport]);

  /**
   * Handle Change events
   */

  const handleChanged = (event) => {
    setReport(event.target.value);
  };

  const updateProperty = (e) => {
    setUser(e.target.value)
  }

  function displaySelectedReport() {
    _displayReport({user, selectedReport, selectedDate,empSiteCode})
    .catch(e => handleError(e, "Unable To Fetch Selected Report.Please Check Property Selection and Date."));
  }

  const fetchReport = useCallback(() => {
    _displayReport({user, selectedReport, selectedDate})
    .catch(e => handleError(e, "Unable To Fetch Selected Report.Please Check Property Selection and Date"));
  }, [])

  const handleSubmit = (event) => {
    alert('Your favorite flavor is: ' + this.state.value);
    event.preventDefault();
  }

  return (<Page>

    <LoadingIndicator visible={loadingReport}/>

    <PageToolbar
        title={'Apex Night Audit Reports'}
        subtitle={'Select a report below.'}
    >
        <FormControl variant="standard" sx={{ m: 1, minWidth: 145 }}>
          <InputLabel id="demo-simple-select-label">Select A Property</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={user}
              label="Property"
              onChange={updateProperty}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {
              session.map((result) => (<MenuItem
                  value={result}>{result.propName}</MenuItem>))
            }

          </Select>
        </FormControl>

      <DateField
          label="Date picker"
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          max={CoreDate.today()}
          fullWidth
      />

      <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={displaySelectedReport}
          className={classes.button}
          disabled={loadingReport}
      >View Report</Button>

    </PageToolbar>

    <PageContent disableBottomGutter>

      <FormControl className={classes.formControl} sx={{ ml: 2}}>
        <RadioGroup
            row
            aria-labelledby="Apex-Night-Audit-Reports"
            name="controlled-radio-buttons-group"
            value={selectedReport}
            onChange={handleChanged}
            required
        >
          <FormControlLabel
              className={classes.formControlLabel}
              value="DEP" control={<Radio/>}
              label="Advanced Deposit Ledger"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="IN" control={<Radio/>}
              label="In-House Ledger"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="HSE" control={<Radio/>}
              label="Staff/House Ledger"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="OUT" control={<Radio/>}
              label="Checked Out Ledger"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="CXL" control={<Radio/>}
              label="No-Show and Canceled Ledger"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DAY" control={<Radio/>}
              label="Daily Report"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DPA" control={<Radio/>}
              label="Department Audit"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="REV" control={<Radio/>}
              label="Revenue Report All Prop"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="REV_" control={<Radio/>}
              label="Revenue Report Per Prop"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="RIH" control={<Radio/>}
              label="In House Guest List"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="RHA" control={<Radio/>}
              label="Arrivals List"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="RHD" control={<Radio/>}
              label="Departures List"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="BNK" control={<Radio/>}
              label="Daily Banking Summary"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DA1" control={<Radio/>}
              label="House Count - Day End"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DA2" control={<Radio/>}
              label="House Count - Day Start"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="ACT" control={<Radio/>}
              label="Reservation Activity Report"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="TR2" control={<Radio/>}
              label="Reminder Trace Report"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="TR1" control={<Radio/>}
              label="Auto Trace Report"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DAR" control={<Radio/>}
              label="AR Department Audit"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="FTA" control={<Radio/>}
              label="FO to AR Transfers Report"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="ALL" control={<Radio/>}
              label="Ledger Summary"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="RTX" control={<Radio/>}
              label="Room Rate Audit"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="TRB" control={<Radio/>}
              label="Transfer Bookings"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DBS" control={<Radio/>}
              label="Daily Balancing Summary"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="MAN" control={<Radio/>}
              label="Managers Report All Prop"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="MAN_" control={<Radio/>}
              label="Managers Report Per Prop"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="ADJ" control={<Radio/>}
              label="Adjustment Audit"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="COR" control={<Radio/>}
              label="Corrections Audit"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="DPT" control={<Radio/>}
              label="Transferred Transactions Audit"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="AGE" control={<Radio/>}
              label="AR Age Analysis"
              sx={{ mr: 7}}/>
          <FormControlLabel
              className={classes.formControlLabel}
              value="MNT" control={<Radio/>}
              label="Maintenance Report"
              sx={{ mr: 7}}/>
        </RadioGroup>
      </FormControl>

    </PageContent>

  </Page>);

};

export default DashboardPage;
