/*
 * Created by Paul Engelke on 17 November 2021.
 */

/**
 * A set of possible values returned by the {@link useSessionStatus} hook.
 */
export const SessionStatuses = Object.freeze({
  Pending: 'Pending',
  Authenticated: 'Authenticated',
  NotAuthenticated: 'NotAuthenticated',
});

export default SessionStatuses;
