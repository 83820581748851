import ClientsActionType from "../constants/action-types/clientsActionType"
import {StateUtility} from "@hti-ui/redux-core";

const DEFAULT_STATE = StateUtility.State({
    client: [],
  },{async: true,});
  
  export default (state = DEFAULT_STATE, action) => {
    switch (action.type){
      case ClientsActionType.Request:
        return StateUtility.defaultRequest(state);
  
      case ClientsActionType.FailRequest:
        return StateUtility.defaultFailRequest(state);
  
      case ClientsActionType.Set:
        return StateUtility.defaultCompleteRequest({
          ...state,
          client: action.data,
        });
  
      default:
        return state;
    }
  }