/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the property redux state.
 */
const ViewReportActionTypes = ActionTypeUtility.createActionTypes(
    "ViewReport",
    {
      Request: "Request",
      FailRequest: "FailRequest",
      Set: "Set",
    },
);

export default ViewReportActionTypes;
