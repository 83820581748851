import React, {Component, useCallback, useEffect, useState} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import {useSelector} from "react-redux";
import DensityTableRowComponent from "./DensityTableRowComponent";
import DensityTableHeadComponent from "./DensityTableHeadComponent";
import DensityHeadingComponent from "./DensityHeadingComponent";
import {
  makeStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {fetchRoomAvailability} from "../../actions/roomAvailabilityActions";
import {StateUtility, useActions} from "@hti-ui/redux-core";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import {LoadingIndicator} from "@hti-ui/react-web-material";

const useStyles = makeStyles({
  root: {
    width: '100%',
    minWidth: 800,
    marginBottom: 20,
  },
  tableContainer: {
    minWidth: 800,
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 800,
  }
});

const DensityTableComponent = props => {
  const classes = useStyles();

  const loading = useSelector(state => StateUtility.areBusy(
      state.rooms,
  ));

  const {propertyCode, propertyName, propertySiteCode, propertyId} = props;

  const {handleError} = useGlobalMessenger();
  const [_fetchRoomAvailability] = useActions([fetchRoomAvailability]);

  /**
   * Fetches relevant data for the page.
   */
  const fetchData = useCallback(() => {
        _fetchRoomAvailability({propertySiteCode})
        .catch(e => handleError(e, "Unable to fetch room availability."));
      },
      [_fetchRoomAvailability],
  );

  useEffect(() => fetchData(), [])

  const {rooms} = useSelector(state => ({
    rooms: state.rooms.room,
  }));

  let rows = [];
  let inventoryMap = new Map();

  const roomTypes = rooms
  .filter(property => property.propertyCode === propertyCode)
  // .sort((a, b) => Sorter.compare(b.id, a.id));

  if (roomTypes) {

    roomTypes.forEach((doc) => {
      let key = doc.roomTypeCode;
      if (inventoryMap.get(key) === undefined) {
        inventoryMap.set(key, []);
      }
      inventoryMap.get(key).push(doc);
    })

    // sort inventory
    let keys = [...inventoryMap.keys()].sort((a, b) => {
      let dataA = inventoryMap.get(a)[0];
      let dataB = inventoryMap.get(b)[0];

      let nameA = (dataA.availability ?? 0)
          + dataA.roomTypeCode.toUpperCase(); // ignore upper and lowercase
      let nameB = (dataB.availability ?? 0)
          + dataB.roomTypeCode.toUpperCase(); // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    keys.forEach((key, index) => {
      rows.push(<DensityTableRowComponent
          propertyId={propertyId}
          key={'row-'+index}
          availability={inventoryMap.get(key)}
      />)
    });

  }

  let colWidths = [];
  for(let i = 0; i < 21; i++) {
    colWidths.push(90/21+'%')
  }

  return (
      <Paper className={classes.root}>
        <LoadingIndicator visible={loading}/>
        {roomTypes && roomTypes.length > 0 && (
            <DensityHeadingComponent
                propertyCode={propertyCode}
                propertyId={propertyId}
            />)}
        <div className={classes.tableContainer}>
          <Table>
            <colgroup>
              <col style={{width:'10%'}}/>
              {colWidths.map((colWidth, i) => {
                return(
                    <col key={i} style={{width: colWidth}} />
                )
              })}
            </colgroup>
            <TableHead>
              {inventoryMap.size > 0 &&
              <DensityTableHeadComponent
                  roomTypes={inventoryMap.values().next().value}
              />}
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </div>
      </Paper>
  );

};

Table.propTypes = {};
export default DensityTableComponent;
