/*
 * Created by Paul Engelke on 29 November 2021.
 */

import {useHistory} from "react-router-dom";
import {useCallback} from "react";
import {useActions} from "@hti-ui/redux-core";
import {signOut} from "../actions/sessionActions";
import RouteNames from "../constants/routeNames";

/**
 * A callback that performs sign out functionality and navigation.
 * @return {function(): void}
 */
const useSignOutCallback = () => {
  const history = useHistory();
  const _signOut = useActions(signOut);
  return useCallback(() => {
    _signOut()
    .finally(() => history.push(RouteNames.SignInPage));
  }, [history, _signOut]);
};

export default useSignOutCallback;
