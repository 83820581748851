/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for setting the session context for the signed-in user.
 */
const SessionActionTypes = ActionTypeUtility.createActionTypes(
    "Session",
    {

      Request: "Request",
      FailRequest: "FailRequest",
      CompleteRequest: "CompleteRequest",

      SetUser: "SetUser",
      SetUserRole: "SetUserRole",

    },
);

export default SessionActionTypes;
