/*
 * Created by Paul Engelke on 17 November 2021.
 */

import AppAppearanceActionTypes
  from "../constants/action-types/appAppearanceActionTypes";
import ThemeTypes from "../constants/themeTypes";

const DEFAULT_STATE = {
  theme: ThemeTypes.Light,
};

/**
 * A redux state reducer for the app's appearance.
 */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case AppAppearanceActionTypes.Init:
      return {...state, ...action.options};
    case AppAppearanceActionTypes.SetTheme:
      return {...state, theme: action.theme};
    default:
      return state;
  }
};
