/*
 * Created by Paul Engelke on 18 November 2021.
 */

/**
 * A complete set of the possible global message types.
 */
const GlobalMessageTypes = Object.freeze({
  Success: "success",
  Warning: "warning",
  Error: "error",
  Info: "info",
});

export default GlobalMessageTypes;
