/*
 * Created by Paul Engelke on 25 March 2021.
 */

/**
 * An abstract class for stored models in local storage.
 */
export class AbstractModel {

  /**
   * The local storage key for the model.
   * @type {string}
   * @private
   */
  _key;

  /**
   * Creates a new model.
   * @param {string} key The model's key, used to identify the stored value in
   * local storage.
   */
  constructor(key) {
    if (new.target === AbstractModel) {
      throw new TypeError('You cannot instantiate an instance of this class.');
    }
    this._key = key;
  }

  /**
   * Sets the key-value pair in local storage for this model.
   * @param {*} value The value to be stored. This will be serialized to a
   * string value.
   */
  set(value) {
    localStorage.setItem(this._key, this.serialize(value));
  }

  /**
   * Gets the value stored in local storage for this model.
   * @return {*}
   */
  get() {
    return this.deserialize(localStorage.getItem(this._key));
  }

  /**
   * Removes the data stored for this model.
   */
  remove() {
    localStorage.removeItem(this._key);
  }

  /**
   * Serializes the given value for storage.
   * @param {*} value The value to be serialized.
   * @return {string}
   * @protected
   */
  serialize(value) {
    throw new Error('Not implemented!');
  }

  /**
   * Deserializes the given string value.
   * @param {string} value The string value that was stored in local storage.
   * @return {*}
   * @protected
   */
  deserialize(value) {
    throw new Error('Not implemented!');
  }

}
