/*
 * Created by Paul Engelke on 17 November 2021.
 */

import {makeStyles, Typography} from "@material-ui/core";
import logo from "../../../assests/images/png/apex-night-reports-logo22-dark-background.png"

/**
 * The navigation drawer header component.
 */
const Header = () => {
  const classes = useStyles();
  return (<div className={classes.root}>
    <div className={classes.title}>
      {/*<Typography className={classes.titlePrefix}>Night</Typography>*/}
      {/*<Typography className={classes.titleSuffix}>Audit</Typography>*/}
      <img
          className={classes.logo}
          src={logo}
          alt={'apex-night-reports-logo22-dark-background'}
      />
    </div>
    {/*<Typography className={classes.subtitle}>*/}
    {/*  Apex Night Audit Reports*/}
    {/*</Typography>*/}
  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(),
  },
  logo: {maxWidth: 300},
}));

export default Header;
