/*
 * Created by Paul Engelke on 30 November 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import CustomerActionTypes from "../constants/action-types/customerActionTypes";

const DEFAULT_STATE = StateUtility.State(
    {data: []},
    {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A redux reducer for customer data.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CustomerActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case CustomerActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);

    case CustomerActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);

    case CustomerActionTypes.Set:
      return StateUtility.defaultSet(state, action.data);

    case CustomerActionTypes.Merge:
      return StateUtility.defaultSet(
          state,
          StateUtility.mergeData(
              state.data,
              action.data,
              i => i.instance_uuid,
          ),
      );

    case GlobalActionTypes.Reset:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
